<template>
    <svg
        width="59"
        height="58"
        viewBox="0 0 59 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_589_4188)">
            <path
                d="M56.3749 5.7998H2.62826C1.96091 5.7998 1.41992 6.34079 1.41992 7.00814V55.8731C1.41992 56.5405 1.96091 57.0815 2.62826 57.0815H56.3749C57.0423 57.0815 57.5833 56.5405 57.5833 55.8731V7.00814C57.5833 6.34079 57.0423 5.7998 56.3749 5.7998Z"
                fill="#F6B91E"
            />
            <path
                d="M5.08008 5.7998V50.9794C5.08008 51.6203 5.33469 52.235 5.7879 52.6882C6.24112 53.1414 6.8558 53.3961 7.49674 53.3961H19.7372C20.3781 53.3961 20.9928 53.6507 21.446 54.1039C21.8992 54.5571 22.1538 55.1718 22.1538 55.8127V57.0211H35.6026V55.8127C35.6026 55.1718 35.8572 54.5571 36.3104 54.1039C36.7636 53.6507 37.3783 53.3961 38.0192 53.3961H51.4801C52.121 53.3961 52.7357 53.1414 53.1889 52.6882C53.6421 52.235 53.8967 51.6203 53.8967 50.9794V5.7998H5.08008Z"
                fill="#CA9819"
            />
            <path
                d="M56.3618 57.9999H2.6393C2.07206 57.9999 1.52806 57.7746 1.12697 57.3735C0.725878 56.9724 0.500546 56.4284 0.500546 55.8612V7.02034C0.494106 6.73543 0.544669 6.45211 0.649264 6.18702C0.753859 5.92193 0.910377 5.68042 1.10962 5.47667C1.30887 5.27292 1.54683 5.11104 1.80951 5.00055C2.0722 4.89006 2.35432 4.83318 2.6393 4.83325H56.3618C56.6468 4.83318 56.9289 4.89006 57.1916 5.00055C57.4543 5.11104 57.6922 5.27292 57.8915 5.47667C58.0907 5.68042 58.2472 5.92193 58.3518 6.18702C58.4564 6.45211 58.507 6.73543 58.5005 7.02034V55.8612C58.5005 56.4284 58.2752 56.9724 57.8741 57.3735C57.473 57.7746 56.929 57.9999 56.3618 57.9999ZM2.6393 6.71825C2.55918 6.71825 2.48234 6.75008 2.42569 6.80673C2.36904 6.86338 2.33721 6.94022 2.33721 7.02034V55.8612C2.33721 55.9413 2.36904 56.0181 2.42569 56.0748C2.48234 56.1314 2.55918 56.1633 2.6393 56.1633H56.3618C56.4419 56.1633 56.5187 56.1314 56.5754 56.0748C56.632 56.0181 56.6639 55.9413 56.6639 55.8612V7.02034C56.6639 6.94022 56.632 6.86338 56.5754 6.80673C56.5187 6.75008 56.4419 6.71825 56.3618 6.71825H2.6393Z"
                fill="black"
            />
            <path
                d="M29.4991 7.02005C29.5071 6.22158 29.3567 5.42944 29.0567 4.68945C28.7566 3.94945 28.3129 3.27627 27.7511 2.70884C27.1892 2.1414 26.5205 1.69097 25.7835 1.38357C25.0466 1.07618 24.256 0.917929 23.4574 0.917969H7.51953V50.9792H23.4574C24.256 50.9792 25.0466 51.1374 25.7835 51.4448C26.5205 51.7522 27.1892 52.2027 27.7511 52.7701C28.3129 53.3375 28.7566 54.0107 29.0567 54.7507C29.3567 55.4907 29.5071 56.2828 29.4991 57.0813V7.02005Z"
                fill="#E5E5E5"
            />
            <path
                d="M29.5003 7.02024C29.5003 6.22173 29.6585 5.43113 29.9659 4.69415C30.2733 3.95718 30.7237 3.28845 31.2912 2.72663C31.8586 2.16482 32.5318 1.72106 33.2718 1.42101C34.0118 1.12097 34.8039 0.970588 35.6024 0.978574H51.4799V50.9794H35.6024C34.7988 50.9713 34.0017 51.1236 33.2577 51.4274C32.5137 51.7312 31.8378 52.1804 31.2696 52.7487C30.7013 53.3169 30.2521 53.9928 29.9483 54.7368C29.6445 55.4808 29.4922 56.2779 29.5003 57.0815V7.02024Z"
                fill="#E5E5E5"
            />
            <path
                d="M34.3828 0.917969H51.4807V50.9792H34.3828V0.917969Z"
                fill="white"
            />
            <path
                d="M7.51953 0.917969H24.6174V50.9792H7.51953V0.917969Z"
                fill="white"
            />
            <path
                d="M29.4995 57.9998C29.2559 57.9998 29.0223 57.903 28.8501 57.7308C28.6779 57.5586 28.5811 57.325 28.5811 57.0814C28.5655 55.7275 28.0206 54.4335 27.0632 53.476C26.1058 52.5186 24.8117 51.9738 23.4578 51.9581H7.5199C7.27634 51.9581 7.04276 51.8613 6.87054 51.6891C6.69831 51.5169 6.60156 51.2833 6.60156 51.0398V0.918089C6.60156 0.674532 6.69831 0.44095 6.87054 0.268729C7.04276 0.0965086 7.27634 -0.000244141 7.5199 -0.000244141L23.4578 -0.000244141C25.3188 0.00295063 27.1026 0.743625 28.4185 2.05952C29.7344 3.37541 30.475 5.15922 30.4782 7.02017V57.0814C30.4785 57.2073 30.4529 57.3318 30.403 57.4474C30.3531 57.5629 30.28 57.667 30.1883 57.7531C30.0965 57.8392 29.988 57.9056 29.8695 57.948C29.751 57.9904 29.6251 58.008 29.4995 57.9998ZM8.43823 50.061H23.4578C24.434 50.0623 25.3992 50.2671 26.2917 50.6624C27.1843 51.0577 27.9846 51.6348 28.6416 52.3568V7.02017C28.6416 5.64536 28.0954 4.32685 27.1233 3.35471C26.1511 2.38257 24.8326 1.83642 23.4578 1.83642H8.43823V50.061Z"
                fill="black"
            />
            <path
                d="M29.5004 57.9998C29.2568 57.9998 29.0232 57.903 28.851 57.7308C28.6788 57.5586 28.582 57.325 28.582 57.0815V7.02021C28.5852 5.15926 29.3259 3.37545 30.6418 2.05955C31.9577 0.743662 33.7415 0.00298726 35.6024 -0.000207507H51.4799C51.6055 -0.00848769 51.7315 0.00913345 51.85 0.0515621C51.9684 0.0939907 52.077 0.160321 52.1687 0.246436C52.2605 0.33255 52.3336 0.436611 52.3835 0.552158C52.4334 0.667705 52.459 0.792272 52.4587 0.918126V50.9794C52.4587 51.2229 52.3619 51.4565 52.1897 51.6287C52.0175 51.801 51.7839 51.8977 51.5404 51.8977H35.6024C34.2276 51.8977 32.9091 52.4439 31.937 53.416C30.9648 54.3881 30.4187 55.7066 30.4187 57.0815C30.4187 57.2021 30.3949 57.3215 30.3488 57.4329C30.3026 57.5443 30.235 57.6455 30.1497 57.7308C30.0644 57.8161 29.9632 57.8837 29.8518 57.9299C29.7404 57.976 29.621 57.9998 29.5004 57.9998ZM35.6024 1.83646C34.2276 1.83646 32.9091 2.3826 31.937 3.35474C30.9648 4.32689 30.4187 5.64539 30.4187 7.02021V52.3569C31.0756 51.6348 31.876 51.0577 32.7685 50.6624C33.6611 50.2671 34.6263 50.0623 35.6024 50.061H50.5616V1.83646H35.6024Z"
                fill="black"
            />
            <path
                d="M38.0805 14.3428H20.9342C19.5996 14.3428 18.5176 15.4248 18.5176 16.7594V33.9057C18.5176 35.2404 19.5996 36.3224 20.9342 36.3224H38.0805C39.4152 36.3224 40.4972 35.2404 40.4972 33.9057V16.7594C40.4972 15.4248 39.4152 14.3428 38.0805 14.3428Z"
                fill="#24B69B"
            />
            <path
                d="M38.045 37.2407H20.9591C20.5139 37.2408 20.0731 37.1523 19.6624 36.9805C19.2516 36.8086 18.8791 36.5569 18.5666 36.2398C18.254 35.9227 18.0076 35.5467 17.8417 35.1336C17.6757 34.7204 17.5936 34.2784 17.6 33.8332V16.7836C17.6 16.3431 17.6869 15.9068 17.8559 15.5C18.0249 15.0931 18.2725 14.7236 18.5846 14.4126C18.8967 14.1016 19.2671 13.8553 19.6745 13.6878C20.082 13.5204 20.5186 13.435 20.9591 13.4365H38.045C38.4855 13.435 38.9221 13.5204 39.3295 13.6878C39.737 13.8553 40.1074 14.1016 40.4195 14.4126C40.7316 14.7236 40.9792 15.0931 41.1482 15.5C41.3172 15.9068 41.4041 16.3431 41.4041 16.7836V33.8332C41.4105 34.2784 41.3284 34.7204 41.1624 35.1336C40.9965 35.5467 40.7501 35.9227 40.4375 36.2398C40.1249 36.5569 39.7525 36.8086 39.3417 36.9805C38.931 37.1523 38.4902 37.2408 38.045 37.2407ZM20.9591 15.2611C20.5542 15.2611 20.1657 15.4211 19.8783 15.7063C19.5908 15.9915 19.4277 16.3787 19.4245 16.7836V33.8332C19.4245 34.2402 19.5862 34.6305 19.874 34.9183C20.1618 35.2061 20.5521 35.3678 20.9591 35.3678H38.045C38.2465 35.3678 38.446 35.3281 38.6322 35.251C38.8184 35.1739 38.9876 35.0608 39.1301 34.9183C39.2726 34.7758 39.3856 34.6067 39.4627 34.4205C39.5399 34.2343 39.5795 34.0347 39.5795 33.8332V16.7836C39.5764 16.3787 39.4133 15.9915 39.1258 15.7063C38.8384 15.4211 38.4499 15.2611 38.045 15.2611H20.9591Z"
                fill="black"
            />
            <path
                d="M34.4065 32.6249H30.709L27.084 28.9999V24.1666C27.084 23.8461 27.2113 23.5388 27.4379 23.3122C27.6645 23.0856 27.9718 22.9583 28.2923 22.9583H34.4307C35.0716 22.9583 35.6863 23.2129 36.1395 23.6661C36.5927 24.1193 36.8473 24.734 36.8473 25.3749V30.2083C36.8473 30.5277 36.784 30.8439 36.6611 31.1387C36.5381 31.4335 36.3579 31.701 36.131 31.9257C35.904 32.1504 35.6347 32.3279 35.3387 32.4479C35.0427 32.568 34.7259 32.6281 34.4065 32.6249Z"
                fill="#1C9F87"
            />
            <path
                d="M33.1255 31.1384H28.2921C28.0487 31.1363 27.8153 31.0412 27.6396 30.8726L24.0146 27.2476C23.9187 27.1649 23.8412 27.063 23.7871 26.9486C23.733 26.8341 23.7035 26.7096 23.7005 26.583V21.7497C23.6906 21.4597 23.7398 21.1707 23.8451 20.9004C23.9505 20.63 24.1097 20.3839 24.3132 20.1771C24.5167 19.9702 24.7601 19.8069 25.0287 19.6971C25.2972 19.5873 25.5854 19.5333 25.8755 19.5384H33.1255C33.6927 19.5384 34.2367 19.7637 34.6378 20.1648C35.0389 20.5659 35.2642 21.1099 35.2642 21.6772V28.9997C35.2642 29.5669 35.0389 30.1109 34.6378 30.512C34.2367 30.9131 33.6927 31.1384 33.1255 31.1384ZM28.6546 29.3017H33.1255C33.2056 29.3017 33.2824 29.2699 33.3391 29.2133C33.3957 29.1566 33.4275 29.0798 33.4275 28.9997V21.6772C33.4276 21.5959 33.3962 21.5178 33.3398 21.4592C33.2835 21.4006 33.2067 21.3661 33.1255 21.363H25.8755C25.7942 21.3661 25.7174 21.4006 25.6611 21.4592C25.6048 21.5178 25.5733 21.5959 25.5734 21.6772V26.1842L28.6546 29.3017Z"
                fill="black"
            />
            <path
                d="M46.5967 34.7997C46.4757 34.8012 46.3556 34.7785 46.2435 34.7328C46.1315 34.6872 46.0297 34.6195 45.9442 34.5339L43.7692 32.3589H39.2621C39.0185 32.3589 38.7849 32.2621 38.6127 32.0899C38.4405 31.9177 38.3438 31.6841 38.3438 31.4406C38.3438 31.197 38.4405 30.9634 38.6127 30.7912C38.7849 30.619 39.0185 30.5222 39.2621 30.5222H44.1558C44.276 30.5218 44.3949 30.5463 44.5051 30.5941C44.6154 30.642 44.7145 30.7121 44.7963 30.8001L47.2129 33.2168C47.2988 33.3012 47.367 33.4018 47.4135 33.5128C47.4601 33.6238 47.484 33.7429 47.484 33.8633C47.484 33.9836 47.4601 34.1028 47.4135 34.2138C47.367 34.3248 47.2988 34.4254 47.2129 34.5097C47.1345 34.5965 47.0397 34.6668 46.9339 34.7166C46.8281 34.7664 46.7135 34.7946 46.5967 34.7997Z"
                fill="black"
            />
            <path
                d="M44.1581 20.1425H39.2643C39.0405 20.1167 38.8339 20.0095 38.684 19.8413C38.534 19.6731 38.4512 19.4556 38.4512 19.2302C38.4512 19.0049 38.534 18.7874 38.684 18.6192C38.8339 18.451 39.0405 18.3437 39.2643 18.3179H43.7714L45.9464 16.1429C46.1226 15.9973 46.3467 15.9226 46.575 15.9334C46.8033 15.9442 47.0193 16.0397 47.1809 16.2013C47.3425 16.363 47.4381 16.579 47.4489 16.8073C47.4597 17.0356 47.385 17.2597 47.2393 17.4358L44.8227 19.8525C44.7378 19.944 44.635 20.017 44.5206 20.0669C44.4063 20.1168 44.2829 20.1425 44.1581 20.1425Z"
                fill="black"
            />
            <path
                d="M45.3762 26.2568H39.2621C39.0185 26.2568 38.7849 26.1601 38.6127 25.9879C38.4405 25.8156 38.3438 25.5821 38.3438 25.3385C38.3438 25.0949 38.4405 24.8614 38.6127 24.6891C38.7849 24.5169 39.0185 24.4202 39.2621 24.4202H45.3762C45.6198 24.4202 45.8534 24.5169 46.0256 24.6891C46.1978 24.8614 46.2946 25.0949 46.2946 25.3385C46.2946 25.5821 46.1978 25.8156 46.0256 25.9879C45.8534 26.1601 45.6198 26.2568 45.3762 26.2568Z"
                fill="black"
            />
            <path
                d="M19.7356 20.1425H14.8419C14.6016 20.1423 14.3712 20.0467 14.2014 19.8767L11.7848 17.46C11.6392 17.2839 11.5644 17.0598 11.5752 16.8315C11.586 16.6032 11.6816 16.3871 11.8432 16.2255C12.0048 16.0639 12.2209 15.9684 12.4491 15.9576C12.6774 15.9468 12.9016 16.0215 13.0777 16.1671L15.2527 18.3421H19.7598C19.9836 18.3679 20.1902 18.4751 20.3401 18.6433C20.4901 18.8116 20.5729 19.029 20.5729 19.2544C20.5729 19.4797 20.4901 19.6972 20.3401 19.8654C20.1902 20.0337 19.9836 20.1409 19.7598 20.1667L19.7356 20.1425Z"
                fill="black"
            />
            <path
                d="M12.4018 34.7997C12.2826 34.801 12.1644 34.7781 12.0544 34.7324C11.9443 34.6867 11.8446 34.6192 11.7614 34.5339C11.6755 34.4495 11.6073 34.3489 11.5608 34.2379C11.5142 34.1269 11.4902 34.0078 11.4902 33.8874C11.4902 33.7671 11.5142 33.6479 11.5608 33.5369C11.6073 33.4259 11.6755 33.3253 11.7614 33.241L14.178 30.8243C14.2598 30.7363 14.3589 30.6661 14.4691 30.6183C14.5794 30.5705 14.6983 30.546 14.8184 30.5464H19.7122C19.9558 30.5464 20.1893 30.6431 20.3616 30.8154C20.5338 30.9876 20.6305 31.2212 20.6305 31.4647C20.6305 31.7083 20.5338 31.9419 20.3616 32.1141C20.1893 32.2863 19.9558 32.3831 19.7122 32.3831H15.2293L13.0543 34.5581C12.9668 34.6393 12.8641 34.7025 12.7521 34.744C12.6402 34.7855 12.5211 34.8044 12.4018 34.7997Z"
                fill="black"
            />
            <path
                d="M19.7376 26.2568H13.6234C13.3799 26.2568 13.1463 26.1601 12.9741 25.9879C12.8018 25.8156 12.7051 25.5821 12.7051 25.3385C12.7051 25.0949 12.8018 24.8614 12.9741 24.6891C13.1463 24.5169 13.3799 24.4202 13.6234 24.4202H19.7376C19.9811 24.4202 20.2147 24.5169 20.3869 24.6891C20.5592 24.8614 20.6559 25.0949 20.6559 25.3385C20.6559 25.5821 20.5592 25.8156 20.3869 25.9879C20.2147 26.1601 19.9811 26.2568 19.7376 26.2568Z"
                fill="black"
            />
            <path
                d="M20.9556 43.3425C20.7756 43.3414 20.5998 43.2874 20.4502 43.1871C20.3006 43.0869 20.1837 42.9449 20.1142 42.7788C20.0447 42.6127 20.0255 42.4298 20.0591 42.2529C20.0927 42.076 20.1776 41.9128 20.3031 41.7838L22.4781 39.6088V35.1017C22.4781 34.8581 22.5749 34.6245 22.7471 34.4523C22.9193 34.2801 23.1529 34.1833 23.3965 34.1833C23.64 34.1833 23.8736 34.2801 24.0458 34.4523C24.2181 34.6245 24.3148 34.8581 24.3148 35.1017V39.935C24.3162 40.0571 24.2922 40.1781 24.2443 40.2904C24.1965 40.4027 24.1258 40.5039 24.0369 40.5875L21.6202 43.0042C21.5403 43.1051 21.4396 43.1875 21.3249 43.2459C21.2102 43.3043 21.0842 43.3373 20.9556 43.3425Z"
                fill="black"
            />
            <path
                d="M38.0421 43.3425C37.923 43.3431 37.805 43.3199 37.6951 43.2742C37.5851 43.2286 37.4853 43.1614 37.4017 43.0767L34.985 40.66C34.896 40.5764 34.8254 40.4753 34.7776 40.3629C34.7297 40.2506 34.7057 40.1296 34.7071 40.0075V35.1742C34.7071 34.9306 34.8038 34.6971 34.9761 34.5248C35.1483 34.3526 35.3819 34.2559 35.6254 34.2559C35.869 34.2559 36.1026 34.3526 36.2748 34.5248C36.447 34.6971 36.5438 34.9306 36.5438 35.1742V39.6813L38.7188 41.8563C38.8443 41.9853 38.9292 42.1485 38.9628 42.3254C38.9964 42.5023 38.9772 42.6852 38.9077 42.8513C38.8382 43.0174 38.7213 43.1594 38.5717 43.2597C38.4221 43.3599 38.2463 43.4139 38.0663 43.415L38.0421 43.3425Z"
                fill="black"
            />
            <path
                d="M29.5004 43.3425C29.2589 43.3425 29.0271 43.2474 28.8552 43.0778C28.6834 42.9082 28.5852 42.6777 28.582 42.4363V35.1017C28.582 34.8581 28.6788 34.6245 28.851 34.4523C29.0232 34.2801 29.2568 34.1833 29.5004 34.1833C29.7439 34.1833 29.9775 34.2801 30.1497 34.4523C30.3219 34.6245 30.4187 34.8581 30.4187 35.1017V42.4363C30.4155 42.6777 30.3174 42.9082 30.1455 43.0778C29.9736 43.2474 29.7418 43.3425 29.5004 43.3425Z"
                fill="black"
            />
            <path
                d="M35.6019 16.4812C35.4813 16.4812 35.3619 16.4575 35.2505 16.4113C35.1391 16.3652 35.0378 16.2975 34.9526 16.2122C34.8673 16.127 34.7997 16.0257 34.7535 15.9143C34.7074 15.8029 34.6836 15.6835 34.6836 15.5629V10.7296C34.6832 10.6094 34.7077 10.4905 34.7555 10.3802C34.8033 10.27 34.8735 10.1709 34.9615 10.0891L37.3782 7.67247C37.5543 7.52685 37.7784 7.45214 38.0067 7.46294C38.235 7.47374 38.4511 7.56928 38.6127 7.73089C38.7743 7.89249 38.8698 8.10855 38.8806 8.33684C38.8914 8.56513 38.8167 8.78925 38.6711 8.96539L36.4961 11.1404V15.6354C36.4782 15.8621 36.3769 16.074 36.2117 16.2303C36.0465 16.3865 35.8292 16.4759 35.6019 16.4812Z"
                fill="black"
            />
            <path
                d="M23.4601 16.4812C23.2166 16.4812 22.983 16.3845 22.8108 16.2122C22.6386 16.04 22.5418 15.8064 22.5418 15.5629V11.0679L20.3668 8.89288C20.2212 8.71674 20.1465 8.49262 20.1573 8.26433C20.1681 8.03604 20.2636 7.81998 20.4252 7.65838C20.5868 7.49677 20.8029 7.40123 21.0312 7.39043C21.2595 7.37963 21.4836 7.45434 21.6597 7.59996L24.0764 10.0166C24.1644 10.0984 24.2346 10.1975 24.2824 10.3077C24.3302 10.418 24.3547 10.5369 24.3543 10.657V15.4904C24.3642 15.6149 24.3486 15.7402 24.3084 15.8585C24.2682 15.9768 24.2043 16.0856 24.1206 16.1784C24.0369 16.2711 23.9352 16.3458 23.8216 16.3979C23.708 16.45 23.585 16.4783 23.4601 16.4812Z"
                fill="black"
            />
            <path
                d="M29.5004 16.4814C29.2568 16.4814 29.0232 16.3847 28.851 16.2125C28.6788 16.0402 28.582 15.8067 28.582 15.5631V8.2406C28.582 7.99704 28.6788 7.76346 28.851 7.59124C29.0232 7.41902 29.2568 7.32227 29.5004 7.32227C29.7439 7.32227 29.9775 7.41902 30.1497 7.59124C30.3219 7.76346 30.4187 7.99704 30.4187 8.2406V15.5631C30.4187 15.6837 30.3949 15.8031 30.3488 15.9145C30.3026 16.0259 30.235 16.1272 30.1497 16.2125C30.0644 16.2977 29.9632 16.3654 29.8518 16.4115C29.7404 16.4577 29.621 16.4814 29.5004 16.4814Z"
                fill="black"
            />
        </g>
        <defs>
            <clipPath id="clip0_589_4188">
                <rect
                    width="58"
                    height="58"
                    fill="white"
                    transform="translate(0.5)"
                />
            </clipPath>
        </defs>
    </svg>
</template>
