<template>
    <DefaultLayout>
        <Head title="Welcome back" />
        <AuthHeader />
        <div class="container">
            <LoginIcon class="mt-[32px] w-[58px] h-[58px]" />
            <p class="title">Welcome back</p>

            <form class="auth-form" @submit.prevent="logIn">
                <div class="auth-form__section">
                    <label class="auth-form__label">Email</label>
                    <input
                        v-model="form.email"
                        type="email"
                        id="email"
                        required
                        class="auth-form__input"
                        :class="{
                            'auth-form__invalid': errors.email,
                        }"
                        @focus="isEmailFocused = true"
                        @blur="isEmailFocused = false"
                    />
                </div>
                <div
                    v-if="errors.email"
                    v-html="errors.email"
                    class="auth-form__error"
                ></div>

                <div class="auth-form__section mt-[16px]">
                    <label class="auth-form__label">Password</label>
                    <input
                        v-model="form.password"
                        :type="passwordFieldType"
                        id="password"
                        class="auth-form__input auth-form__input--password"
                        :class="{
                            'auth-form__invalid': form.invalid('password'),
                        }"
                        required
                        @keydown.enter.prevent="logIn"
                        @focus="isPasswordFocused = true"
                        @blur="isPasswordFocused = false"
                    />
                    <div
                        class="auth-form__password-icon"
                        @click="togglePasswordVisibility"
                    >
                        <component :is="passwordIcon" />
                    </div>
                </div>

                <div
                    v-if="errors.password"
                    v-html="errors.password"
                    class="auth-form__error"
                ></div>

                <div class="auth-form__forgot-password">
                    <Link :href="route('password.request')">
                        Forgot password?
                    </Link>
                </div>

                <BaseButton :is-loading="isLoading">
                    <span>Log in</span></BaseButton
                >
            </form>

            <footer class="footer">
                <div class="footer__text">Don’t have an account?</div>
                <a
                    class="footer__action"
                    href="https://quiz.l-earn.ai/#welcome"
                    target="_blank"
                    >Start the quiz</a
                >
            </footer>
        </div>
    </DefaultLayout>
</template>

<script>
import {Head} from '@inertiajs/vue3'
import {Link} from '@inertiajs/vue3'
import {useForm} from 'laravel-precognition-vue-inertia'

import LoginIcon from '@/Icons/auth-form/LoginIcon.vue'
import EyeOpened from '@/Icons/auth-form/EyeOpened.vue'
import EyeClosed from '@/Icons/auth-form/EyeClosed.vue'
import BaseButton from '@/Components/BaseButton.vue'
import AuthHeader from '@/Components/Auth/AuthHeader.vue'
import DefaultLayout from '@/Layouts/DefaultLayout.vue'

export default {
    components: {
        Head,
        Link,
        EyeOpened,
        EyeClosed,
        LoginIcon,
        AuthHeader,
        BaseButton,
        DefaultLayout,
    },
    props: {
        errors: {type: Object, default: () => {}},
    },
    data() {
        return {
            form: useForm('post', route('login.attempt'), {
                email: '',
                password: '',
                remember: false,
            }).setValidationTimeout(500),
            isEmailFocused: false,
            isPasswordFocused: false,
            showPassword: false,
            isLoading: false,
        }
    },
    computed: {
        passwordFieldType() {
            return this.showPassword ? 'text' : 'password'
        },
        passwordIcon() {
            return this.showPassword ? 'EyeOpened' : 'EyeClosed'
        },
    },
    methods: {
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword
        },
        logIn() {
            this.isLoading = true
            this.form.submit({
                onSuccess: () => {
                    this.form.reset()
                },
                onFinish: () => {
                    this.isLoading = false
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    @apply mt-[16px] font-[700] text-[24px] leading-[30px] text-[--primary-typography-color];
}

.footer {
    @apply flex flex-col items-center justify-center gap-[4px] mt-auto text-[--primary-typography-color];
}

.footer__text {
    @apply text-[16px] leading-[26px];
}

.footer__action {
    @apply text-[14px] leading-[18px] font-[700] cursor-pointer;

    &:hover {
        @apply underline;
    }
}

.auth-form {
    @apply w-full mt-[16px];
}

.auth-form__label {
    @apply mb-[6px] font-[700] text-[14px] leading-[18px] text-[--primary-typography-color];
}

.auth-form__section {
    @apply relative flex flex-col w-full;
}

.auth-form__input {
    @apply w-full h-[48px] rounded-[16px] border-[1px] border-solid border-[--separator-color-2] text-[--primary-typography-color];

    &:focus,
    &:active {
        @apply border-[--primary-typography-color];
        box-shadow: none;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 50px var(--typography-color-white) inset;
        -webkit-text-fill-color: var(--primary-typography-color);
    }

    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 50px var(--typography-color-white) inset;
        -webkit-text-fill-color: var(--primary-typography-color);
    }
}

.auth-form__input--password {
    font-family: 'Arial';
}

.auth-form__forgot-password {
    @apply ml-auto mt-[16px] mb-[24px] w-fit font-[700] text-[14px] leading-[18px] text-[--primary-typography-color];
}

.auth-form__invalid {
    @apply border-[--error-color];
}

.auth-form__error {
    @apply font-[600] text-[12px] leading-[20px] text-[--error-color];
}

.auth-form__password-icon {
    @apply absolute right-[10px] bottom-[14px] w-[20px] cursor-pointer flex flex-row items-center;
}
</style>
